import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b4da3b0c = () => interopDefault(import('../pages/instructions/index.vue' /* webpackChunkName: "pages/instructions/index" */))
const _5accae47 = () => interopDefault(import('../pages/no-license.vue' /* webpackChunkName: "pages/no-license" */))
const _6465c790 = () => interopDefault(import('../pages/practice/index.vue' /* webpackChunkName: "pages/practice/index" */))
const _7eafb148 = () => interopDefault(import('../pages/student/index.vue' /* webpackChunkName: "pages/student/index" */))
const _92b5d1b2 = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _7b31b817 = () => interopDefault(import('../pages/tutor/index.vue' /* webpackChunkName: "pages/tutor/index" */))
const _67f357a0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e796dbf8 = () => interopDefault(import('../pages/class-test/_id.vue' /* webpackChunkName: "pages/class-test/_id" */))
const _170cb74f = () => interopDefault(import('../pages/test/_id.vue' /* webpackChunkName: "pages/test/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/instructions",
    component: _b4da3b0c,
    name: "instructions"
  }, {
    path: "/no-license",
    component: _5accae47,
    name: "no-license"
  }, {
    path: "/practice",
    component: _6465c790,
    name: "practice"
  }, {
    path: "/student",
    component: _7eafb148,
    name: "student"
  }, {
    path: "/test",
    component: _92b5d1b2,
    name: "test"
  }, {
    path: "/tutor",
    component: _7b31b817,
    name: "tutor"
  }, {
    path: "/",
    component: _67f357a0,
    name: "index"
  }, {
    path: "/class-test/:id?",
    component: _e796dbf8,
    name: "class-test-id"
  }, {
    path: "/test/:id",
    component: _170cb74f,
    name: "test-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
