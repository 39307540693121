import { Item } from 'item-player'
import TestAlreadyMadeError from '@/errors/TestAlreadyMadeError'

export const state = () => ({
  atrTest: null,
  task: null,
  items: [],
  assets: []
})

export const getters = {
  itemCount: (state) => {
    return state.items.length
  }
}

export const mutations = {
  SET_ATR_TEST(state, atrTest) {
    state.atrTest = atrTest
  },
  SET_TASK(state, task) {
    state.task = task
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ASSETS(state, assets) {
    state.assets = assets
  }
}

export const actions = {
  async loadAtrTest({ commit }, testId) {
    const atrTest = (await this.$axios.$get(`/atr-tests/${testId}`)).atrTest
    if (atrTest && atrTest.finished) {
      throw new TestAlreadyMadeError('Je mag deze toets niet meer maken')
    }
    commit('SET_ATR_TEST', atrTest)
  },
  async setTaskData({ commit }, data) {
    const { task, items, assets } = data
    commit('SET_TASK', task)
    commit(
      'SET_ITEMS',
      items.map((item) =>
        Item.createFromObject({
          ...item,
          assets: assets.filter((asset) => {
            return asset.itemId === item.id
          })
        })
      )
    )
    commit('SET_ASSETS', assets)
  }
}
