<template>
  <div class="main-container">
    <NavigationBar v-if="user" :user="user" @logout="logout()">
      <div class="mr-12 cursor-pointer text-xl" @click="home()">
        Bareka Automatiseringstoets Rekenen
      </div>
      <template #buttons>
        <IconButton @click="navigateTo('/test')">
          <IconTask /> ATR-toets
        </IconButton>
      </template>
    </NavigationBar>
    <Nuxt />
    <ModalContainer />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  IconButton,
  IconTask,
  ModalContainer,
  NavigationBar
} from 'component-library'

export default {
  components: { IconButton, IconTask, ModalContainer, NavigationBar },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  },
  methods: {
    async logout() {
      window.location.href = '/auth/logout'
    },
    home() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 4.125rem;
}
</style>
