const middleware = {}

middleware['home'] = require('../middleware/home.js')
middleware['home'] = middleware['home'].default || middleware['home']

middleware['student'] = require('../middleware/student.js')
middleware['student'] = middleware['student'].default || middleware['student']

middleware['tutor'] = require('../middleware/tutor.js')
middleware['tutor'] = middleware['tutor'].default || middleware['tutor']

export default middleware
